<template>
    <div class="container">
        <!-- 头部 -->
        <Header :tab="-1" />
        <!-- 身体 -->
        <div class="main">
            <div class="banner wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                <img src="../../assets/imgs/tuceng16.png" class="banner_img" alt />
                <div class="banner_box">
                    <div class="banner_box_title">OA/CRM/ERP/检测管理系统</div>
                    <div class="banner_box_txt">金牌管理软件高稳定性，支持定制开发</div>
                </div>
            </div>

            <!-- 主体 -->
            <div class="main_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                <!-- 顶部 -->
                <div class="main_box_top wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
                    :data-wow-offset="height_top">
                    <div class="title_box wow animate__slideInUp" data-wow-iteration="1" data-wow-delay="0.2s"
                        :data-wow-offset="height_top">
                        <div>个性化管理系统定制</div>
                        <div class="title_box_text">
                            专注客户业务需求，孜孜不倦的钻研和掌握新技术和新应用，并把我们的丰硕成果与客户分享是我们一直追求的目标。
                        </div>
                    </div>
                    <div class="main_box_top_l wow animate__slideInUp" data-wow-iteration="1" data-wow-delay="0.2s"
                        :data-wow-offset="height_top">
                        <img src="../../assets/imgs/tuceng18.png" class="main_box_top_l_img" alt="">
                    </div>
                    <div class="main_box_top_r_box wow animate__slideInUp" data-wow-iteration="1" data-wow-delay="0.4s"
                        :data-wow-offset="height_top">
                        <div class="main_box_top_r" :style="{ height: tempHeight + 'px' }" ref="topRbox"
                            v-for="(item, index) in list" :key="index">
                            <div class="main_box_top_r_img_box" :style="{ height: tempHeight * 0.7 + 'px' }">
                                <img :src="item.image" class="main_box_top_r_img_box_img" alt="">
                            </div>
                            <div class="main_box_top_r_btn wow animate__slideInUp" data-wow-iteration="1"
                                data-wow-delay="0.6s" :data-wow-offset="height_top"
                                :style="{ height: tempHeight * 0.3 + 'px' }">
                                <div class="main_box_top_r_title">{{ item.title }}</div>
                                <div class="main_box_top_r_txt">{{ item.summary }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 优势 -->
                <div class="main_box_center wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
                    :data-wow-offset="height_top">
                    <div class="title_box wow animate__slideInUp" data-wow-iteration="1" data-wow-delay="0.2s"
                        :data-wow-offset="height_top">
                        <div>服务优势</div>
                        <div class="title_box_text">
                            专注客户业务需求，孜孜不倦的钻研和掌握新技术和新应用，并把我们的丰硕成果与客户分享是我们一直追求的目标。
                        </div>
                    </div>
                    <div class="main_box_center_box">

                        <div class="main_box_center_box_l">
                            <div class="main_box_center_box_l_item" v-if="list1.length > 0">
                                <div class="main_box_center_box_l_item_l">
                                    <div class="main_box_center_box_l_item_l_title">{{ list1[0].title }}</div>
                                    <div class="main_box_center_box_l_item_l_txt">{{ list1[0].summary }}</div>
                                </div>
                                <div class="main_box_center_box_l_item_l_imgbox">
                                    <img :src="list1[0].image" class="main_box_center_box_l_item_l_imgbox_img" alt="">
                                </div>
                            </div>
                            <div class="main_box_center_box_l_item" v-if="list1.length > 2">
                                <div class="main_box_center_box_l_item_l">
                                    <div class="main_box_center_box_l_item_l_title">{{ list1[2].title }}</div>
                                    <div class="main_box_center_box_l_item_l_txt">{{ list1[2].summary }}</div>
                                </div>
                                <div class="main_box_center_box_l_item_l_imgbox">
                                    <img :src="list1[2].image" class="main_box_center_box_l_item_l_imgbox_img" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="main_box_center_box_c">
                            <img src="../../assets/imgs/proservice-three.jpg" class="main_box_center_box_c_img" alt="">
                        </div>
                        <div class="main_box_center_box_r">
                            <div class="main_box_center_box_l_item" v-if="list1.length > 1">
                                <div class="main_box_center_box_l_item_l_imgbox">
                                    <img :src="list1[1].image" class="main_box_center_box_l_item_l_imgbox_img" alt="">
                                </div>
                                <div class="main_box_center_box_l_item_l">
                                    <div class="main_box_center_box_l_item_l_title">{{ list1[1].title }}</div>
                                    <div class="main_box_center_box_l_item_l_txt">{{ list1[1].summary }}</div>
                                </div>
                            </div>
                            <div class="main_box_center_box_l_item" v-if="list1.length > 3">
                                <div class="main_box_center_box_l_item_l_imgbox">
                                    <img :src="list1[3].image" class="main_box_center_box_l_item_l_imgbox_img" alt="">
                                </div>
                                <div class="main_box_center_box_l_item_l">
                                    <div class="main_box_center_box_l_item_l_title">{{ list1[3].title }}</div>
                                    <div class="main_box_center_box_l_item_l_txt">{{ list1[3].summary }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- 推荐 -->
                <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                    <div>推荐案例</div>
                    <div class="title_box_text">
                        眼光高度决定品牌厚度！
                    </div>
                </div>
                <div class="main_box_center_btn wow animate__slideInUp" data-wow-iteration="1"
                    :data-wow-offset="height_top">
                    <div @click="toDetails(item.id)" v-for="(item, index) in list2" :key="index"
                        class="main_box_center_btn_box wow animate__slideInUp" :data-wow-delay="index / 10 + 0.2 + 's'"
                        data-wow-iteration="1" :data-wow-offset="height_top">
                        <div class="main_box_center_btn_item">
                            <div class="main_box_center_btn_item_img_box" ref="btnItem"
                                :style="{ height: tempHeight + 'px' }">
                                <img :src="item.list_image1" class="main_box_center_btn_item_img" alt />
                            </div>
                            <div class="main_box_center_btn_item_box">
                                <div class="main_box_center_btn_item_box_title">{{ item.title }}</div>
                                <div class="main_box_center_btn_item_box_txt">{{ item.summary }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <Footer class="wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top" />
    </div>
</template>
  
<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import storage from "@/utils/storage";
import { articlellist12, articlellist13, projectsrecommends } from "@/api/axios/axios.api";

import { WOW } from "wowjs";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            height_top: storage.height_top,
            tempHeight: '',
            tempHeights: '',
            timer: null,
            list: null,
            list1: [],
            list2: null,
        };
    },
    methods: {
        // 案例详情
        toDetails(e) {
            this.$router.push({
                name: 'sucsessdetails',
                // query: {
                //     id: e.id
                // }
            })
        },
        // 设置高度
        height() {
            console.log(this.tempHeight);
            //进入页面元素默认宽度
            this.timer = setInterval(() => {
                if (!this.tempHeight || !this.tempHeights) {
                    if (this.$refs.btnItem) {
                        this.tempHeights = this.$refs.btnItem[0].offsetWidth
                    }
                    if (this.$refs.topRbox) {
                        console.log(this.list);
                        this.tempHeight = this.$refs.topRbox[0].offsetWidth
                    }
                    clearInterval(this.timer)
                    this.timer = null
                } else {
                    clearInterval(this.timer)
                    this.timer = null
                }
            }, 0);
            //根据屏幕缩放自动获取页面宽度
            window.onresize = () => {
                return (() => {
                    //窗口缩放自动获取元素宽度
                    if (this.$refs.btnItem) {
                        this.tempHeights = this.$refs.btnItem[0].offsetWidth //宽度
                    }
                    if (this.$refs.topRbox) {
                        this.tempHeight = this.$refs.topRbox[0].offsetWidth
                    }
                })()
            }
        },
        // 获取数据
        async getData() {
            // 介绍
            let res = await articlellist12()
            this.list = res.data.list
            // 优势
            let rec = await articlellist13()
            this.list1 = rec.data.list
            //  推荐
            // 案例推荐
            let ret = await projectsrecommends(5)
            this.list2 = ret.data.list
            if (ret.data.list.length || res.data.list.length) {
                this.height();
            }
        },
    },
    created() { },
    mounted() {
        this.getData()
        this.$nextTick(() => {
            let wow = new WOW({
                live: true,
            });
            wow.init();
            // this.height();
        });
    },
    watch: {},
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
  
<style lang="less" scoped>
.banner {
    color: #fff;
    height: 801px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .banner_img {
        position: absolute;
        height: 801px;
        width: 100%;
    }

    .banner_box {
        padding: 0 10%;
        z-index: 100;
        color: #FFFFFF;
        margin-top: 70px;

        .banner_box_title {
            font-size: 70px;
            margin-bottom: 90px;
        }

        .banner_box_txt {
            font-size: 30px;
        }
    }

}

// 身体
.main_box {
    background-color: #fff;

    // 顶部
    .main_box_top {
        .title_box {
            text-align: center;
            font-size: 26px;
            color: #333333;
            padding: 70px 0;
            background: transparent;

            .title_box_text {
                color: #999999;
                font-size: 16px;
                margin-top: 45px;
            }
        }

        .main_box_top_l {
            width: 700px;
            height: 444px;
            margin: 0 auto;
            margin-bottom: 90px;
            transition: all 0.5s;

            .main_box_top_l_img {
                width: 700px;
                height: 444px;
            }
        }


        .main_box_top_r_box {
            // width: 100%;
            height: 30%;
            padding: 0 8%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            color: #999999;

            .main_box_top_r {
                width: calc(90% / 2);
                display: flex;
                flex-direction: column;

                .main_box_top_r_img_box {
                    width: 100%;
                    overflow: hidden;
                    transition: all 0.5s;

                    .main_box_top_r_img_box_img {
                        width: 100%;
                        height: 100%;
                        transition: all 0.5s;
                    }
                }

                .main_box_top_r_img_box:hover {
                    transition: all 0.5s;
                    transform: scale(1.2);
                    // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }

                // .main_box_top_r_img_box:hover .main_box_top_r_img_box_img{
                //     transition: all 0.5s;
                //     transform: scale(1.2);
                //     box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                // }

                .main_box_top_r_btn {
                    flex: 1;
                    padding: 8% 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .main_box_top_r_title {
                        box-sizing: border-box;
                        color: #333333;
                        font-size: 30px;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                    }

                    .main_box_top_r_txt {
                        font-size: 16px;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                    }
                }
            }
        }

        .main_box_top_l:hover {
            transition: all 0.5s;
            transform: scale(1.2);
            // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
        }
    }

    // 优势
    .main_box_center {
        padding: 0 10% 8% 10%;
        background: #fff;
        box-sizing: border-box;

        .title_box {
            text-align: center;
            font-size: 26px;
            color: #333333;
            padding: 70px 0;
            background: transparent;

            .title_box_text {
                color: #999999;
                font-size: 16px;
                margin-top: 45px;
            }
        }

        .main_box_center_box {
            display: flex;
            justify-content: space-between;

            .main_box_center_box_l {
                flex: 1;

                .main_box_center_box_l_item {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 200px;

                    .main_box_center_box_l_item_l {
                        color: #333333;
                        font-size: 16px;
                        text-align: right;

                        .main_box_center_box_l_item_l_title {
                            font-size: 20px;
                            margin-bottom: 27px;
                        }

                        .main_box_center_box_l_item_l_txt {
                            line-height: 25px;
                        }
                    }

                    .main_box_center_box_l_item_l_imgbox {
                        width: 72px;
                        height: 72px;
                        margin-left: 73px;
                        border-radius: 50%;

                        .main_box_center_box_l_item_l_imgbox_img {
                            width: 72px;
                            height: 72px;
                            border-radius: 50%;
                        }
                    }
                }

                .main_box_center_box_l_item:nth-child(2) {
                    margin-bottom: 0;
                }

            }

            .main_box_center_box_c {
                flex: 1;
                display: flex;
                justify-content: center;
                padding: 0 50px;

                .main_box_center_box_c_img {
                    width: 100%;
                }
            }

            .main_box_center_box_r {
                flex: 1;

                .main_box_center_box_l_item {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 200px;

                    .main_box_center_box_l_item_l {
                        color: #333333;
                        font-size: 16px;
                        text-align: left;

                        .main_box_center_box_l_item_l_title {
                            font-size: 20px;
                            margin-bottom: 27px;
                        }

                        .main_box_center_box_l_item_l_txt {
                            line-height: 25px;
                        }
                    }

                    .main_box_center_box_l_item_l_imgbox {
                        width: 72px;
                        height: 72px;
                        margin-right: 73px;
                        border-radius: 50%;

                        .main_box_center_box_l_item_l_imgbox_img {
                            width: 72px;
                            height: 72px;
                            border-radius: 50%;
                        }
                    }
                }

                .main_box_center_box_l_item:nth-child(2) {
                    margin-bottom: 0;
                }
            }
        }
    }

    // 推荐
    .title_box {
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 28px;
        background-color: #f5f5f5;

        .title_box_text {
            margin-top: 21px;
            font-weight: normal;
            color: #666666;
            font-size: 18px;
        }
    }

    .main_box_center_btn {
        padding: 0 10%;
        padding-bottom: 66px;
        display: flex;
        justify-content: space-between;
        background-color: #f5f5f5;
        align-items: center;

        .main_box_center_btn_box {
            width: calc(100% / 3);
            display: flex;
            align-items: center;
            justify-content: center;

            .main_box_center_btn_item {
                // width: 407px;
                width: 100%;
                // height: 514px;
                background: #FFFFFF;
                padding: 2%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                transition: all 0.5s;

                .main_box_center_btn_item_img_box {
                    width: 100%;
                    // height: 378px;
                    overflow: hidden;

                    .main_box_center_btn_item_img {
                        width: 100%;
                        height: 100%;
                        transition: all 0.5s;
                    }

                }

                .main_box_center_btn_item_box {
                    flex: 1;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 8% 0;

                    .main_box_center_btn_item_box_title {
                        color: #333333;
                        font-size: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        margin-bottom: 5%;
                    }

                    .main_box_center_btn_item_box_txt {
                        color: #999999;
                        font-size: 14px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                    }
                }

            }

            .main_box_center_btn_item:hover {
                transition: all 0.5s;
                box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            }

            .main_box_center_btn_item:hover .main_box_center_btn_item_img {
                transform: scale(1.5);
                transition: all 0.5s;
            }
        }

        .main_box_center_btn_box:nth-child(2) {
            margin: 0 5%;
        }
    }
}
</style>
  